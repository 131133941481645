import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TinymceEditor = _resolveComponent("TinymceEditor")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{fullscreen: _ctx.fullscreen}, "tinymce-container"]),
    style: _normalizeStyle({width: _ctx.containerWidth})
  }, [
    _createVNode(_component_TinymceEditor, {
      id: _ctx.id,
      modelValue: _ctx.tinymceContent,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tinymceContent) = $event)),
      modelModifiers: { value: true },
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input', $event.target.value))),
      init: _ctx.initOptions
    }, null, 8, ["id", "modelValue", "init"])
  ], 6))
}